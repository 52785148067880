import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PageSection from '../../components/PageSection/PageSection';
import MakeDonationWidget from '../../components/MakeDonationWidget/MakeDonationWidget';
import DonateButton from '../../components/DonateButton/DonateButton';
import CustomLogicDonationInput from './CustomLogicWidget/CustomLogicDonationInput';
import { open } from './checkoutPopupSlice';
import {
    updateDonationAmount,
    updateDonationCurrency,
    updateDonationPaymentMethod,
    selectDonationAmount,
    selectDonationCurrency,
    selectBitEnabled,
    updateDonationTier,
    selectDonationTier,
    selectCustomLogicState,
    updateCustomLogicState,
} from './checkoutSlice';
import { useHistory } from 'react-router-dom';
import {
    selectPageSpecificCampaignData,
    selectSelectedLayerItem,
    selectEnableDonations,
    selectHideDonateArea,
    selectEnabledLayerDonation,
    selectDonationTiersSettings,
    selectCustomLogicSettings,
} from '../campaign/campaignSlice';
import filterQueryParams from 'client/helpers/filterQueryParams';
import { getSelectedDonationTier } from 'common/helpers/donationTier';
import { GTM_EVENTS, triggerGtmEvent } from 'client/helpers/gtm';
import { CAMPAIGN_CUSTOM_LOGIC_TYPES } from 'common/constants/campaign';

const useStyles = makeStyles(() => ({
    donates: {
        display: 'grid',
        gridTemplateColumns: '1fr 181px',
        gridGap: '20px 20px',
        gap: '20px 20px',

        '@media (max-width: 1023px)': {
            gridGap: '15px',
            gap: '15px',
            marginBottom: 30,
        },
        '@media (max-width: 600px)': {
            marginBottom: 12,
            padding: 0,
            gridTemplateColumns: '1fr',
            gridGap: 0,
            gap: 0,
            width: '100%',
            fontSize: 22,
            '& button': {
                position: 'fixed',
                left: 0,
                bottom: 0,
                zIndex: 10,
            },
        },
    },
}));

function DonationWidget(props) {
    const { multiplier, currencies, disabled = false } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const enableDonations = useSelector(selectEnableDonations);
    const hideDonateArea = useSelector(selectHideDonateArea);
    const { donationText, showDonationText } = useSelector(
        selectPageSpecificCampaignData,
    );
    const amount = useSelector(selectDonationAmount);
    const currency = useSelector(selectDonationCurrency);
    const history = useHistory();
    const selectedLayerItem = useSelector(selectSelectedLayerItem);
    const isBitEnabled = useSelector(selectBitEnabled);
    const isLayerDonationEnabled = useSelector(selectEnabledLayerDonation);
    const customLogicSettings = useSelector(selectCustomLogicSettings);
    const customLogicState = useSelector(selectCustomLogicState);
    const donationTiersSettings = useSelector(selectDonationTiersSettings);
    const donationTier = useSelector(selectDonationTier);

    const handleOpen = useCallback(paymentMethod => {
        dispatch(updateDonationPaymentMethod(paymentMethod));
        dispatch(
            open({
                layerItem: isLayerDonationEnabled ? selectedLayerItem : null,
                paymentMethod,
            }),
        );
        const { filteredQueryString: queryString } = filterQueryParams(
            document.location.search,
        );
        const virtualLocation = `${history.location.pathname.replace(
            /\/+$/,
            '',
        )}/donate/${queryString}`;
        triggerGtmEvent(GTM_EVENTS.CLICK_DONATE, {
            eventCategory: 'main-donate-button',
        });
        triggerGtmEvent(GTM_EVENTS.OPEN_CHECKOUT, {
            eventCategory: 'virtual-pageview',
            virtualLocation: virtualLocation,
        });
        history.push(virtualLocation);
    }, []);

    const handleAmountChange = useCallback(event => {
        dispatch(updateDonationAmount(event.target.value));
    }, []);

    const handleCurrencyChange = useCallback(event => {
        dispatch(updateDonationCurrency(event.target.value));
    }, []);

    useEffect(() => {
        // initialize custom logic state per enabled custom logic
        if (
            customLogicSettings?.customLogicType ===
                CAMPAIGN_CUSTOM_LOGIC_TYPES.PURIM &&
            !customLogicState?.timestamp
        ) {
            dispatch(updateCustomLogicState({ numDonors: 1 }));
        }
    }, [customLogicSettings?.customLogicType, customLogicState?.timestamp]);

    useEffect(() => {
        dispatch(
            updateDonationTier(
                getSelectedDonationTier(
                    donationTiersSettings,
                    currency,
                    amount,
                    { ...customLogicSettings, ...customLogicState },
                ),
            ),
        );
    }, [
        currency,
        amount,
        donationTiersSettings,
        customLogicSettings?.customLogicType,
        customLogicState?.timestamp,
    ]);

    return (
        !hideDonateArea && (
            <PageSection
                className={classNames(classes.donates, 'donation-widget')}
            >
                <MakeDonationWidget
                    amount={amount}
                    currency={currency}
                    multiplier={multiplier}
                    currencies={currencies}
                    donationText={donationText}
                    showDonationText={showDonationText}
                    CustomLogicInputComponent={CustomLogicDonationInput}
                    donationTier={donationTier}
                    onAmountChange={handleAmountChange}
                    onCurrencyChange={handleCurrencyChange}
                    disabled={disabled}
                />
                <DonateButton
                    onClick={handleOpen}
                    disabled={!enableDonations || disabled}
                    multiplier={multiplier}
                    isBitEnabled={isBitEnabled}
                >
                    <FormattedMessage
                        id="DonateButton.text"
                        defaultMessage="Donate Now!"
                    />
                </DonateButton>
            </PageSection>
        )
    );
}

DonationWidget.propTypes = {
    currencies: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
        }),
    ),
    multiplier: PropTypes.number,
    disabled: PropTypes.bool,
};

export default DonationWidget;
