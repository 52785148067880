import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import FlagIcon from 'client/components/Icons/Flag';
import { Box } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Avatar from '../Avatar/Avatar';
import Progress from '../Progress/Progress';
import { scrollToElement } from '../../hooks/scrollTo';
import { ReactComponent as LinkIcon } from './LinkIcon.svg';
import { ReactComponent as CloseIcon } from './CloseIcon.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { currencySign } from 'common/helpers';
import Text from '../Text/Text';
import getLayerLabel from 'client/helpers/campaign/getLayerLabel';
import classNames from 'classnames';
import { changeColorAlpha } from 'client/helpers/colorUtils';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
    root: {
        background: palette.background.default,
        boxShadow: `0 0px 20px ${changeColorAlpha(palette.text.primary, 0.15)}`,
        border: `1px solid ${palette.grey[100]}`,
        borderRadius: 3,
        padding: '33px 45px',
        position: 'relative',
        fontFamily: typography.fontFamily,

        '@media (max-width: 600px)': {
            padding: '35px 15px 20px',
        },
    },
    bonusGoalBanner: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        right: '8%',
        padding: '0 0.5em',
        letterSpacing: '0.05rem',
        background: `linear-gradient(90deg, ${palette.secondary.light} 0%, ${palette.secondary.main} 100%)`,
        borderRadius: '0 0 5px 5px',
        color: palette.secondary.contrastText,
        fontSize: '1.125rem',
        fontWeight: 600,
        '& svg': {
            marginInlineEnd: spacing(1),
            stroke: palette.secondary.contrastText,
        },
        '@media (max-width: 600px)': {
            left: 0,
            right: '50%',
            transform: 'translate(50%)',
            fontSize: '1rem',
            padding: '0 0.325em',
        },
        '[dir=rtl] &': {
            right: 'auto',
            left: '8%',
            '@media (max-width: 600px)': {
                right: 0,
                left: '50%',
                transform: 'translate(-50%)',
            },
        },
    },
    bonusRound: {
        borderWidth: 1.5,
        borderStyle: 'solid',
        borderImage: `linear-gradient(45deg, ${palette.secondary.light}, ${palette.secondary.light}) 1`,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 25,
    },
    avatar: {
        marginRight: 18,
        flexShrink: 0,

        'html[dir=rtl] &': {
            marginRight: 0,
            marginLeft: 18,
        },
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    },
    name: {
        color: palette.text.primary,
        fontWeight: 'bold',
        fontSize: '1.75rem',

        '@media (max-width: 600px)': {
            fontSize: '1.25rem',
        },
    },
    description: {
        color: palette.text.primary,
        fontWeight: 400,
        fontSize: '1rem',

        '@media (max-width: 600px)': {
            fontSize: typography.pxToRem(14),
        },
    },
    info: {
        margin: `${spacing(2)}px 0`,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        '@media (max-width: 600px)': {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            margin: `${spacing(2)}px 0 0`,
        },
    },
    counts: {
        marginTop: spacing(1),
        textAlign: 'center',
        display: 'flex',
        maxWidth: '100%',
        '@media (max-width: 600px)': {
            overflowX: 'scroll',
            padding: `${spacing(1)}px 0`,
            margin: `${spacing(1)}px 0`,
        },
    },
    count: {
        display: 'flex',
        alignItems: 'center',
        color: palette.primary.main,
        fontSize: '1.125rem',
        lineHeight: 1,
        marginRight: 10,
        'html[dir=rtl] &': {
            marginRight: 0,
            marginLeft: 10,
        },
        '@media (max-width: 600px)': {
            fontSize: '0.95rem',
        },
    },
    amounts: {
        display: 'flex',
        flexDirection: 'column',
        color: palette.text.primary,
        marginTop: spacing(1),
        '& svg': {
            marginRight: 5,
            '@media (max-width: 600px)': {
                marginTop: spacing(0.5),
            },
        },
        '& strong': {
            color: palette.text.primary,
            fontSize: '1.5rem',
            marginRight: spacing(1),

            '@media (max-width: 600px)': {
                fontSize: '1.25rem',
                flexDirection: 'column',
            },
        },
        'html[dir=rtl] &': {
            '& svg': {
                marginRight: 0,
                marginLeft: 5,
            },
            '& strong': {
                marginRight: 0,
                marginLeft: spacing(1),
            },
        },
    },
    amountsFirstRow: {
        display: 'flex',
        alignItems: 'center',
    },
    originalGoalText: {
        fontWeight: 600,
    },
    close: {
        position: 'absolute',
        left: 6,
        top: 6,
        cursor: 'pointer',
        padding: 10,
        margin: 0,
        background: 'none',
        border: 0,

        'html[dir=rtl] &': {
            left: 'auto',
            right: 6,
        },

        '&:hover, &:focus': {
            filter: 'brightness(0)',
        },
        '& svg': {
            display: 'block',
            width: 12,
            height: 12,
        },
    },
    viewLink: {
        display: 'inline-flex',
        alignItems: 'center',
        position: 'absolute',
        color: palette.primary.main,
        top: 12,
        right: 45,
        cursor: 'pointer',

        '&:hover, &:focus': {
            textDecoration: 'underline',
        },
        '& svg': {
            fill: palette.primary.main,
            marginLeft: 10,
        },
        'html[dir=rtl] &': {
            right: 'auto',
            left: 45,
            '& svg': {
                marginLeft: 0,
                marginRight: 10,
            },
        },
        '@media (max-width: 600px)': {
            right: 15,
            fontSize: '0.9rem',
        },
    },
    bonusGoalViewLink: {
        top: 35,
        '@media (max-width: 600px)': {
            top: 25,
        },
    },
    donateBtn: {
        minWidth: 160,
        padding: '3px 13px',
    },
    goalText: {
        '@media (max-width: 600px)': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    itemAmount: {
        color: ({ hasEnteredBonusGoal }) =>
            hasEnteredBonusGoal ? palette.secondary.main : palette.primary.main,
        fontSize: '1.75rem',
        '@media (max-width: 600px)': {
            fontSize: '1.125rem',
        },
    },
    itemProgress: {
        color: palette.text.primary,

        '@media (max-width: 600px)': {
            fontSize: '0.875rem',
        },
    },
    loadingMsg: {
        textAlign: 'center',
        padding: '1em',
        color: palette.text.primary,
    },
}));

function ItemCardPopup(props) {
    const {
        children,
        className,
        avatarSrc,
        name,
        description,
        donorsCount,
        amount,
        onView,
        onClose,
        goalAmount = 0,
        currency = 'USD',
        isDisableAutoscroll,
        childLayers,
        baseGoalPercent = Math.round((amount / (goalAmount || 1)) * 100),
        hasEnteredBonusGoal = false,
        bonusGoalAmount = 0,
    } = props;
    const { locale: lang } = useIntl();
    const classes = useStyles({ hasEnteredBonusGoal });
    const { palette } = useTheme();
    const cardRef = useRef();

    const totalGoalAmount =
        Math.round(
            hasEnteredBonusGoal ? goalAmount + bonusGoalAmount : goalAmount,
        ) || 1;

    useEffect(() => {
        if (cardRef && cardRef.current && !isDisableAutoscroll) {
            scrollToElement(cardRef.current);
        }
    }, []);

    return (
        <div
            className={classNames(
                classes.root,
                { [classes.bonusRound]: hasEnteredBonusGoal },
                className,
            )}
            ref={cardRef}
        >
            {hasEnteredBonusGoal && (
                <div className={classes.bonusGoalBanner}>
                    <FlagIcon color={palette.secondary.contrastText} />
                    <FormattedMessage
                        id="common.bonusGoal"
                        defaultMessage="Bonus Goal!"
                    />
                </div>
            )}
            <div className={classes.header}>
                <Avatar size={90} className={classes.avatar}>
                    <img
                        src={
                            avatarSrc
                                ? avatarSrc
                                : 'https://via.placeholder.com/40x40'
                        }
                        alt=""
                    />
                </Avatar>
                <div>
                    <div className={classes.name}>{name}</div>
                    {description && (
                        <div className={classes.description}>{description}</div>
                    )}
                </div>
            </div>
            {goalAmount > 0 && !isNaN(baseGoalPercent) && (
                <>
                    {hasEnteredBonusGoal ? (
                        <Progress
                            percent={(goalAmount * 100) / totalGoalAmount}
                            label={`${baseGoalPercent}%`}
                            bonusPercent={Math.round(
                                (amount / totalGoalAmount) * 100,
                            )}
                            size={12}
                        />
                    ) : (
                        <Progress percent={baseGoalPercent} size={12} />
                    )}
                </>
            )}
            {!isNaN(amount) ? (
                <div className={classes.info}>
                    <div className={classes.amounts}>
                        <div className={classes.amountsFirstRow}>
                            <strong>
                                <Text className={classes.itemAmount}>
                                    {currencySign(currency)}
                                    {amount.toLocaleString('en-EN')}
                                </Text>
                            </strong>
                            {goalAmount > 0 ? (
                                <div className={classes.itemProgress}>
                                    <FormattedMessage
                                        id={
                                            hasEnteredBonusGoal
                                                ? 'Amount.layerItemBonusGoal'
                                                : 'Amount.layerItemGoal'
                                        }
                                        values={{ name }}
                                    />{' '}
                                    {currencySign(currency)}
                                    {totalGoalAmount.toLocaleString('en-EN')}
                                </div>
                            ) : (
                                <span>
                                    <FormattedMessage
                                        id="Amount.raised"
                                        defaultMessage="Raised"
                                    />
                                </span>
                            )}
                        </div>
                        {hasEnteredBonusGoal && (
                            <div>
                                <Box mt={0.5} />
                                <span className={classes.originalGoalText}>
                                    <FormattedMessage
                                        id={'common.originalGoal'}
                                    />
                                </span>
                                {': '}
                                {currencySign(currency)}
                                {Math.round(goalAmount).toLocaleString('en-EN')}
                            </div>
                        )}
                    </div>
                    <div className={classes.counts}>
                        {!!donorsCount && (
                            <div className={classes.count}>
                                {donorsCount + ' '}
                                <FormattedMessage id="Amount.donors" />
                            </div>
                        )}
                        {childLayers.map(layer => (
                            <div key={layer.id} className={classes.count}>
                                {`${layer.layerItemsCount} ${getLayerLabel(
                                    layer,
                                    lang,
                                )}`}
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className={classes.loadingMsg}>
                    Analytics in progress...
                </div>
            )}
            <a
                onClick={onView}
                className={classNames(classes.viewLink, {
                    [classes.bonusGoalViewLink]: hasEnteredBonusGoal,
                })}
            >
                <FormattedMessage id="Link.viewPage" /> <LinkIcon />
            </a>
            {typeof onClose === 'function' && (
                <button className={classes.close} onClick={onClose}>
                    <CloseIcon />
                </button>
            )}
            <>{children}</>
        </div>
    );
}

ItemCardPopup.propTypes = {
    children: PropTypes.node,
    onView: PropTypes.func,
    avatarSrc: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    donorsCount: PropTypes.number,
    teamsCount: PropTypes.number,
    amount: PropTypes.number,
    goalAmount: PropTypes.number,
    className: PropTypes.string,
    onClose: PropTypes.func,
    currency: PropTypes.string,
    isDisableAutoscroll: PropTypes.bool,
    childLayers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            locales: PropTypes.object,
            layerItemsCount: PropTypes.number,
        }),
    ),
    hasEnteredBonusGoal: PropTypes.bool,
    bonusGoalAmount: PropTypes.number,
    baseGoalPercent: PropTypes.number,
};

export default ItemCardPopup;
