import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import {
    selectCurrencySettings,
    selectSuggestedAmounts,
} from '../campaign/campaignSlice';
import { currencySign, integerAmount } from 'common/helpers';

const useStyles = makeStyles(({ spacing }) => ({
    root: {
        marginBottom: spacing(3),
    },
    heading: {
        fontWeight: 500,
    },
    suggestedAmounts: {
        display: 'flex',
        marginTop: spacing(2),
        justifyContent: 'center',

        '@media (max-width: 390px)': {
            flexWrap: 'wrap',
        },
    },
    suggestedAmount: {
        marginLeft: spacing(1),
        marginRight: spacing(1),
        paddingLeft: spacing(3),
        paddingRight: spacing(3),
        fontSize: '1rem',
        fontWeight: 900,
        overflow: 'hidden',

        '@media (max-width: 600px)': {
            marginLeft: spacing(0.5),
            marginRight: spacing(0.5),
            paddingLeft: spacing(1.5),
            paddingRight: spacing(1.5),
        },

        '@media (max-width: 390px)': {
            marginBottom: spacing(1),
            width: `calc(100% - ${spacing(1)}px)`,

            '&:last-of-type': {
                marginBottom: 0,
            },
        },
    },
}));

function SuggestedAmounts({ currency, onAmountChange }) {
    const classes = useStyles();
    const currencySettings = useSelector(selectCurrencySettings);
    const suggestedAmounts = useSelector(selectSuggestedAmounts);

    const handleSuggestedAmountSelected = suggestedAmount => {
        onAmountChange(suggestedAmount.amount);
    };

    const convertedAmounts = useMemo(
        () =>
            suggestedAmounts?.map(suggestedAmount => {
                const currencyRate = currencySettings.currencyRates.find(
                    r => r.currency === currency,
                );

                // rate to convert currency to primary currency
                const rate = currencyRate?.rate || 1;

                // divide to convert primary currency to currency
                const convertedAmount = Number(suggestedAmount.amount) / rate;

                return {
                    ...suggestedAmount,
                    currency,
                    amount: integerAmount(convertedAmount),
                };
            }),
        [currency],
    );

    if (!currency || !suggestedAmounts?.length) {
        return null;
    }

    return (
        <div className={classes.root}>
            <div className={classes.heading}>
                <FormattedMessage
                    id="Checkout.suggestedAmounts"
                    defaultMessage="Suggested Amounts"
                />
            </div>
            <div className={classes.suggestedAmounts}>
                {convertedAmounts.map(suggestedAmount => (
                    <Button
                        key={suggestedAmount.id}
                        className={classes.suggestedAmount}
                        color="primary"
                        variant="outlined"
                        onClick={() =>
                            handleSuggestedAmountSelected(suggestedAmount)
                        }
                    >
                        {currencySign(suggestedAmount.currency)}
                        {suggestedAmount.amount}
                    </Button>
                ))}
            </div>
        </div>
    );
}

SuggestedAmounts.propTypes = {
    currency: PropTypes.string,
    onAmountChange: PropTypes.func.isRequired,
};

export default SuggestedAmounts;
