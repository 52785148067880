import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    FormControl,
    FormHelperText,
    FormLabel,
    OutlinedInput,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { currencySign } from 'common/helpers';
import { selectPurimDonationTier } from 'common/helpers/donationTier';
import { selectDonationTiersSettings } from '../../campaign/campaignSlice';
import {
    selectCustomLogicState,
    selectDonationTier,
    updateCustomLogicState,
    updateDonationTier,
} from '../checkoutSlice';
import scrollTo from 'client/helpers/scrollTo';

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: {
        marginTop: spacing(1),
        scrollMargin: spacing(10),
        padding: spacing(2),
        border: `1px solid ${palette.background.accent}`,
        borderRadius: spacing(2),
        color: palette.accent.main,

        '@media (max-width: 600px)': {
            padding: `${spacing(2)}px ${spacing(1)}px`,
        },
    },
    heading: {
        color: palette.primary.main,
        fontWeight: 900,
        fontSize: '1.15rem',
    },
    label: {
        color: palette.accent.main,
        marginBottom: spacing(2),

        '&.Mui-focused': {
            color: palette.accent.main,
        },
    },
    input: {
        '& input': {
            padding: `${spacing(1.5)}px ${spacing(2)}px`,
        },
        '& fieldset': {
            borderColor: palette.accent.main,
        },
    },
    helperText: {
        marginTop: spacing(2),
        color: palette.accent.main,
        fontSize: '0.95rem',
    },
}));

function PurimGivingWidget({ children }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const containerRef = useRef();
    const customLogicState = useSelector(selectCustomLogicState);
    const donationTiersSettings = useSelector(selectDonationTiersSettings);
    const donationTier = useSelector(selectDonationTier);
    const { register, setValue, watch } = useFormContext();

    const { currency, amount } = watch(['currency', 'amount']);

    const handleChange = event => {
        const value = event.target.value
            .replace(/^0*/, '')
            .replace(/[^0-9]/g, '');

        const numDonors = value ? Number(value) : '';

        dispatch(updateCustomLogicState({ numDonors }));
    };

    useEffect(() => {
        register('customFields.purim.numDonors');
        register('customFields.purim.donationTierId');
    }, [register]);

    useEffect(() => {
        if (!customLogicState?.timestamp) {
            dispatch(updateCustomLogicState({ numDonors: 1 }));
            setValue('customFields.purim.numDonors', 1);
        } else {
            setValue(
                'customFields.purim.numDonors',
                customLogicState.numDonors,
            );
        }
    }, [customLogicState?.timestamp]);

    useEffect(() => {
        const donationTier = selectPurimDonationTier(
            donationTiersSettings,
            currency,
            amount,
            customLogicState,
        );
        dispatch(updateDonationTier(donationTier));
        setValue(
            `customFields.purim.donationTierId`,
            donationTier ? donationTier.entityId : null,
        );
    }, [currency, amount, customLogicState?.timestamp, donationTiersSettings]);

    useEffect(() => {
        if (containerRef.current) {
            setTimeout(
                () => scrollTo(containerRef.current, { block: 'end' }),
                425,
            );
        }
    }, [containerRef.current]);

    const {
        isHelperTextVisible,
        evyonimCount,
        isMessageVisible,
        totalAmount,
        amountPerEvyon,
        tierDescription,
    } = useMemo(() => {
        const params = {
            isHelperTextVisible: false,
            evyonimCount: '',
            isMessageVisible: false,
            totalAmount: '',
            amountPerEvyon: '',
            tierDescription: '',
        };
        if (!customLogicState?.numDonors) {
            return params;
        }

        params.isHelperTextVisible = true;
        params.evyonimCount = Number(customLogicState.numDonors) * 2;

        if (!currency || !amount || !donationTier) {
            return params;
        }

        params.isMessageVisible = true;

        params.totalAmount = `${currencySign(currency)}${amount}`;

        params.amountPerEvyon = `${currencySign(currency)}${(
            amount / params.evyonimCount
        ).toFixed(2)}`;

        params.tierDescription = donationTier.description?.toLowerCase();

        return params;
    }, [currency, amount, customLogicState?.timestamp, donationTier?.entityId]);

    return (
        <div ref={containerRef} className={classes.root}>
            <div>
                <div className={classes.heading}>
                    <FormattedMessage
                        id="Checkout.purim.heading"
                        defaultMessage="Fulfill the Mitzvah of Matanot La'Evyonim with ease!"
                    />
                </div>
                <div>
                    <FormattedMessage
                        id="Checkout.purim.description"
                        defaultMessage="Simply enter your donation amount, and we’ll distribute it to two evyonim on Purim, ensuring your donation fulfills the halacha."
                    />
                </div>
            </div>
            <Box mt={2} />
            <div>
                <FormControl fullWidth>
                    <FormLabel
                        htmlFor="purim-num-donors"
                        className={classes.label}
                    >
                        <FormattedMessage
                            id="Checkout.purim.numDonorsLabel"
                            defaultMessage="How many people are performing the Mitzvah?"
                        />
                    </FormLabel>
                    <OutlinedInput
                        id="purim-num-donors"
                        className={classes.input}
                        inputProps={{
                            type: 'text',
                            inputMode: 'numeric',
                        }}
                        onChange={handleChange}
                        value={customLogicState?.numDonors}
                        fullWidth
                    />
                    {isHelperTextVisible && (
                        <FormHelperText className={classes.helperText}>
                            <FormattedMessage
                                id="Checkout.purim.numDonorsHelperText"
                                defaultMessage="Your contribution will directly impact <highlight>{evyonimCount}</highlight> Evyonim, ensuring 2 are supported for each donor."
                                values={{
                                    evyonimCount,
                                    highlight(...chunks) {
                                        return <strong>{chunks}</strong>;
                                    },
                                }}
                            />
                        </FormHelperText>
                    )}
                </FormControl>
            </div>
            <Box mt={2} />
            {children}
            {isMessageVisible && (
                <div>
                    <Box mt={2} />
                    <FormattedMessage
                        id="Checkout.purim.donationMessage"
                        defaultMessage="Your donation of <highlight>{totalAmount}</highlight> for Matanot La’Evyonim will support <highlight>{evyonimCount} Evyonim with {tierDescription}</highlight>. Each Evyon will receive <highlight>{amountPerEvyon}</highlight> on the day of Purim"
                        values={{
                            totalAmount,
                            evyonimCount,
                            tierDescription,
                            amountPerEvyon,
                            highlight(...chunks) {
                                return <strong>{chunks}</strong>;
                            },
                        }}
                    />
                </div>
            )}
        </div>
    );
}

PurimGivingWidget.propTypes = {
    children: PropTypes.node,
};

export default PurimGivingWidget;
